body {
  margin: 0;
  font-family: "Barlow", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rbc-agenda-view table.rbc-agenda-table {
    border: none !important;
}

.rbc-month-view, .rbc-time-view {
    background-color: white;
}

.rbc-event-content {
    font-weight: 500;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
    border: none !important;
}

.rbc-header {
    display: none;
}

.rbc-calendar tr {
    border: none !important;
}

.rbc-agenda-time-cell {
    display: none;
}

.rbc-agenda-date-cell {
    padding-top: 12px !important;
    background-color: white;
    color: #505050;
    font-weight: 500;
    border-top: 2px solid #505050;
    text-align: center;
}

.rbc-toolbar-label {
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    color: #344534;
    text-transform: uppercase;
}

.rbc-month-view, .rbc-header, .rbc-row-bg, .rbc-month-row {
    border: none !important;
}

.rbc-day-bg {
    background-color: #F9EEEE;
    border-radius: 10px;
    border: 5px solid white !important;
}

.rbc-date-cell {
    text-align: left !important;
    padding: 12px 15px 2px 15px;
}

.rbc-date-cell > button {
    font-weight: 700;
    font-size: 16px;
}

.rbc-off-range-bg {
    background-color: white !important;
}

.rbc-event {
    border-radius: 10px !important;
}

.rbc-row-segment {
    padding-left: 12px !important;
    padding-right: 12px !important;
    padding-bottom: 2px !important;
}

textarea {
    font-family: "Barlow", sans-serif;
    font-weight: 500;
    font-size: 15px;
    border: 1px solid #CCCCCC;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 100%;
    padding: 10px 15px;
    box-sizing: border-box;
}

.MuiPickerStaticWrapper-root > div > div {
    width: 420px;
    max-height: 476px;
}

.MuiCalendarPicker-root .PrivatePickersSlideTransition-root {
    height: 382px;
}

.MuiCalendarPicker-root {
    max-height: 476px !important;
}

.layout-content-wrapper {
    min-height: calc(100vh - 56px) !important;
}

.MuiPickerStaticWrapper-root .MuiPickersDay-dayWithMargin {
  margin-top: 5px;
  margin-bottom: 5px;
}
